

export const all_classes = [
    {
        value: 'Creché',
        text: 'Creché',
        index:0

    },
    {
        value: 'Nursery 1',
        text: 'Nursery 1',
        index:0

    },
    {
        value: 'Nursery 2',
        text: 'Nursery 2',
        index:1
  
    },
    {
        value: 'Kindergarten 1',
        text: 'Kindergarten 1',
        index:2
    
    },
    {
        value: 'Kindergarten 2',
        text: 'Kindergarten 2',
        index:3
    
    },
    {
        value: 'Basic 1',
        text: 'Basic 1',
        index:4
    
    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
        index:5

    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
        index:6
 
    },
    {
        value: 'Basic 4',
        text: 'Basic 4',
        index:7
    
    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
        index:8

    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
        index:9

    },
    {
        value: 'Basic 7',
        text: 'Basic 7',
        index:10

    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
        index:11

    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
        index:12

    },
    {
        value: 'Basic 10',
        text: 'Basic 10',
        index:13

    },
    {
        value: 'Junior High 2',
        text: 'Junior High 2',
        index:14
   
    },

    {
        value: 'Junior High 3',
        text: 'Junior High 3',
        index:15
      
    }
]


export const lp_classes = [
    {
        value: 'Basic 1',
        text: 'Basic 1',

    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
      
    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
      
    }
]

export const up_classes = [
    {
        value: 'Basic 4',
        text: 'Basic 4',
      
    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
      
    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
      
    }

]



export const all_subjects = [
    
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English',
        value: 'English Language'
    },

    {
        text: 'Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social',
        value: 'Social Studies'
    },
    {
        text: 'I C T',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R M E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'B D T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'O W O P',
        value: 'Our World Our People'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    },
    {
        text:"C Arts",
        value:"Creative Arts"
    },
    {
        text:"C. Tech",
        value:"Career Technology"
    },
    {
        text:"P & Health",
        value:"Physical and Health Education"
    }
]

export const _all_subjects = [
       
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English',
        value: 'English Language'
    },

    {
        text: 'Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social',
        value: 'Social Studies'
    },
    {
        text: 'I C T',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R M E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'B D T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'O W O P',
        value: 'Our World Our People'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    },
    {
        text:"C Arts",
        value:"Creative Arts"
    },
    {
        text:"C. Tech",
        value:"Career Technology"
    },
    {
        text:"P & Health",
        value:"Physical and Health Education"
    }
]

export const _days = [
    {
        text: 'Monday',
        value: "Monday",
    },
    {
        text: 'Tuesday',
        value: "Tuesday",
    },
    {
        text: 'Wednesday',
        value: "Wednesday",
    },
    {
        text: 'Thursday',
        value: "Thursday",
    },
    {
        text: 'Friday',
        value: "Friday",
    }

]

export const religions = [
    {
        text: "African Traditionist",
        value: "African Traditionalist"
    },
    {
        text: "Agnostic",
        value: "Agnostic"
    },
    {
        text: "Atheist",
        value: "Atheist"
    },
    {
        text:"Buddhist",
        value:"Buddhist"
    },
    {
        text: "Christianity",
        value: "Christianity"
    },
    {
        text:"Hinduisim",
        value:"Hinduism"
    },
    {
        text: "Islam",
        value: "Islam"
    },

    {
        text:"Mormon",
        value:"Mormon"
    },
    {
        text:"None",
        value:"None"
    }]

export const marital_status = [
    {
        text:"Single",
        value:'Single'
    },
    {
        text:'Married',
        value:'Married'
    },
    {
        text:"Widowed",
        value:"Widowed"
    },
    {
        text:'Divorced',
        value:"Divorced"
    },
    {
        text:'Engaged',
        value:"Engaged"
    }
]

export const qualifications = [
    {
        text:'B.E.C.E',
        value:"BECE"
    },
    {
        text:"W.A.S.S.C.E",
        value:'WASSCE'
    },
    {
        text:"Diploma",
        value:"Diploma"
    },
    {
        text:"Higher National Diploma",
        value:"HND"
    }, 
    {
        text:"Bachelor",
        value:"Bachelor"
    },
    {
        text:"Masters",
        value:"Masters"
    },
    {
        text:"Doctorate",
        value:"Doctorate"
    },
    {
        text:'None',
        value:'N/A'
    }
]

export const gender = [
    {
        text: 'Male',
        value: "Male"
    },
    {
        text: 'Female',
        value: "Female"
    }
]
     

export const yons = [
    {
        text: "Yes",
        value:"Yes"
    },
    {
        text:"No",
        value:"No"
    }
]

export const lp_courses = [
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English Language',
        value: 'English Language'
    },

    {
        text: 'Integrated Science',
        value: 'Integrated Science'
    },
    {
        text: 'Info & Comm. Tech',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'Religious & Moral Edu',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'Our World Our People',
        value: 'Our World Our People'
    },
    {
        text: 'Fante',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    }
]

export const up_courses = [
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English Language',
        value: 'English Language'
    },

    {
        text: 'Integrated Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social Studies',
        value: 'Social Studies'
    },
    {
        text: 'Info & Comm. Tech',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'Religious & Moral Edu',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'Our World Our People',
        value: 'Our World Our People'
    },
    {
        text: 'Fante',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    }
]

export const class_categories = [
    {
        text:"Pre School",
        value:"pre_school"
    },
    {
        text:"Lower Primary",
        value:'lower_primary'
    },
    {
        text:"Upper Primary",
        value:"upper_primary"
    },
    {
        text:"Junior High",
        value:"junior_high"
    }
]
    
export const jh_courses = [
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English Langu.',
        value: 'English Language'
    },

    {
        text: 'Integ. Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social Studies',
        value: 'Social Studies'
    },
    {
        text: 'Info & Comm. Tech',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R. M. E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'Basic Des. & T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    }
]

export const jh_classes = [
    
    {
        value: 'Basic 7',
        text: 'Basic 7',
    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
    },
    {
        value: 'Basic 10',
        text: 'Basic 10',
    },
    {
        value: 'Junior High 2',
        text: 'Junior High 2',

    },

    {
        value: 'Junior High 3',
        text: 'Junior High 3',

    }
]

export const prs_courses = [
    {
        text:"Numeracy",
        value:"Numeracy"
    },
    {
        text:"Our World Our People",
        value:"OWOP"
    },
    {
        text:"Language and Literacy",
        value:"Language and Lit"
    },
    {
        text:"Writing",
        value:"Writing"
    },
    {
        text:"Phonics",
        value:"Phonics"
    },
    {
        text:"Creative Arts",
        value:"Creative Arts"
    }
]


