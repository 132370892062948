import { wris_header, signature, scale } from "./images";

import _ from "lodash";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// male_holder,

export default function generate_report(ccreport, csrp, num_on_roll, method) {
  const isNewCl = (cls) => ["Basic 7", "Basic 8", "Basic 9"].includes(cls);

  const divider = csrp.n_courses === undefined ? 10 : csrp.n_courses;
  // console.log(csrp)
  const getCoursePosition = (total_score, all_scores) => {
    const uniqueScores = _.uniq(all_scores);
    const grade_position = parseInt(uniqueScores.indexOf(total_score) + 1);
    //getting grade with index values
    if (grade_position.toString().endsWith("1") && grade_position !== 11) {
      return grade_position.toString() + "st";
    } else if (
      grade_position.toString().endsWith("2") &&
      grade_position !== 12
    ) {
      return grade_position.toString() + "nd";
    } else if (
      grade_position.toString().endsWith("3") &&
      grade_position !== 13
    ) {
      return grade_position.toString() + "rd";
    } else {
      return grade_position.toString() + "th";
    }
  };

  const doc_deff = {
    paperSize: "A4",
    watermark: {
      text: "WRIS",
      color: "lightgrey",
      opacity: 0.4,
      bold: true,
      italics: true,
    },
    defaultStyle: { fontSize: 10 },
    content: [
      {
        image: wris_header,
        width: 595,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        image: csrp.profile_image,
        width: 95,
        absolutePosition: { x: 485, y: 68 },
      },
      {
        text: "Term: " + csrp.term,
        absolutePosition: { x: 20, y: 160 },
      },
      {
        text: "Class: " + csrp.class_admitted,
        absolutePosition: { x: 20, y: 190 },
      },
      {
        text:
          "Position: " +
          getCoursePosition(csrp.overrall_aggregate, ccreport.overrall),
        absolutePosition: { x: 320, y: 160 },
      },
      {
        text: "Next Term Begins: " + csrp.next_term,
        absolutePosition: { x: 320, y: 190 },
      },
      {
        text: "Academic Year: " + csrp.academic_year,
        absolutePosition: { x: 20, y: 220 },
      },
      {
        text: "Overrall Score: " + csrp.overrall_aggregate,
        absolutePosition: { x: 320, y: 220 },
      },
      {
        text:
          "Average Score: " +
          Number(csrp.overrall_aggregate / divider).toFixed(2),
        absolutePosition: { x: 20, y: 250 },
      },
      {
        text: "No on Roll: " + num_on_roll,
        absolutePosition: { x: 320, y: 250 },
      },
      {
        text: "Name: " + csrp.last_name + "  " + csrp.other_names,
        absolutePosition: { x: 20, y: 130 },
      },
      {
        absolutePosition: { x: 20, y: 290 },
        style: { fontSize: 10 },
        table: {
          widths: [100, 60, 70, 60, 70, 40, 100],
          heights: 20,
          body: [
            [
              { text: "COURSES", alignment: "center", bold: true },
              { text: "CLASS\nSCORE", alignment: "center", bold: true },
              { text: "EXAMS\nSCORE", alignment: "center", bold: true },
              { text: "TOTAL\nSCORE", alignment: "center", bold: true },
              { text: "CLASS\nPOSITION", alignment: "center", bold: true },
              { text: "GRADE", alignment: "center", bold: true },
              { text: "REMARKS", alignment: "center", bold: true },
            ],
            [
              { text: "Mathematics", bold: true },
              { text: csrp.mcs * 0.5, alignment: "center" },
              { text: csrp.mes * 0.5, alignment: "center" },
              { text: csrp.mts, alignment: "center" },
              {
                text: getCoursePosition(csrp.mts, ccreport.maths),
                alignment: "center",
              },
              { text: csrp.mgr, alignment: "center" },
              { text: csrp.mrm, alignment: "center" },
            ],
            [
              { text: "Int. Science", bold: true },
              { text: csrp.scs * 0.5, alignment: "center" },
              { text: csrp.ses * 0.5, alignment: "center" },
              { text: csrp.sts, alignment: "center" },
              {
                text: getCoursePosition(csrp.sts, ccreport.science),
                alignment: "center",
              },
              { text: csrp.sgr, alignment: "center" },
              { text: csrp.srm, alignment: "center" },
            ],
            [
              { text: "English Lang.", bold: true },
              { text: csrp.ecs * 0.5, alignment: "center" },
              { text: csrp.ees * 0.5, alignment: "center" },
              { text: csrp.ets, alignment: "center" },
              {
                text: getCoursePosition(csrp.ets, ccreport.english),
                alignment: "center",
              },
              { text: csrp.egr, alignment: "center" },
              { text: csrp.erm, alignment: "center" },
            ],
            csrp.class_category === "junior_high"
              ? [
                  { text: "Social Studies", bold: true },
                  { text: csrp.sscs * 0.5, alignment: "center" },
                  { text: csrp.sses * 0.5, alignment: "center" },
                  { text: csrp.ssts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.ssts, ccreport.social),
                    alignment: "center",
                  },
                  { text: csrp.ssgr, alignment: "center" },
                  { text: csrp.ssrm, alignment: "center" },
                ]
              : [
                  { text: "O W O P", bold: true },
                  { text: csrp.owcs * 0.5, alignment: "center" },
                  { text: csrp.owes * 0.5, alignment: "center" },
                  { text: csrp.owts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.owts, ccreport.owop),
                    alignment: "center",
                  },
                  { text: csrp.owgr, alignment: "center" },
                  { text: csrp.owrm, alignment: "center" },
                ],
            [
              { text: "Rel. & Moral Edu.", bold: true },
              { text: csrp.rmcs * 0.5, alignment: "center" },
              { text: csrp.rmes * 0.5, alignment: "center" },
              { text: csrp.rmts, alignment: "center" },
              {
                text: getCoursePosition(csrp.rmts, ccreport.rme),
                alignment: "center",
              },
              { text: csrp.rmgr, alignment: "center" },
              { text: csrp.rmrm, alignment: "center" },
            ],
            [
              { text: "Computing", bold: true },
              { text: csrp.ictcs * 0.5, alignment: "center" },
              { text: csrp.ictes * 0.5, alignment: "center" },
              { text: csrp.ictts, alignment: "center" },
              {
                text: getCoursePosition(csrp.ictts, ccreport.ict),
                alignment: "center",
              },
              { text: csrp.ictgr, alignment: "center" },
              { text: csrp.ictrm, alignment: "center" },
            ],
            csrp.class_category === "lower_primary" ||
            csrp.class_category === "upper_primary"
              ? [
                  { text: "History", bold: true },
                  { text: csrp.hcs * 0.5, alignment: "center" },
                  { text: csrp.hes * 0.5, alignment: "center" },
                  { text: csrp.hts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.hts, ccreport.history),
                    alignment: "center",
                  },
                  { text: csrp.hgr, alignment: "center" },
                  { text: csrp.hrm, alignment: "center" },
                ]
              : csrp.class_category === "junior_high" &&
                isNewCl(csrp.class_admitted)
              ? [
                  { text: "P and Health", bold: true },
                  { text: csrp.phcs * 0.5, alignment: "center" },
                  { text: csrp.phes * 0.5, alignment: "center" },
                  { text: csrp.phts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.phts, ccreport.pandh),
                    alignment: "center",
                  },
                  { text: csrp.phgr, alignment: "center" },
                  { text: csrp.phrm, alignment: "center" },
                ]
              : [
                  { text: "Computing", bold: true },
                  { text: csrp.ictcs * 0.5, alignment: "center" },
                  { text: csrp.ictes * 0.5, alignment: "center" },
                  { text: csrp.ictts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.ictts, ccreport.ict),
                    alignment: "center",
                  },
                  { text: csrp.ictgr, alignment: "center" },
                  { text: csrp.ictrm, alignment: "center" },
                ],
            [
              {
                text: isNewCl(csrp.class_admitted)
                  ? "Creative Arts"
                  : "Creative Art & Design",
                bold: true,
              },
              { text: csrp.cracs * 0.5, alignment: "center" },
              { text: csrp.craes * 0.5, alignment: "center" },
              { text: csrp.crats, alignment: "center" },
              {
                text: getCoursePosition(csrp.crats, ccreport.creative_arts),
                alignment: "center",
              },
              { text: csrp.cragr, alignment: "center" },
              { text: csrp.crarm, alignment: "center" },
            ],

            [
              { text: "Fante", bold: true },
              { text: csrp.fncs * 0.5, alignment: "center" },
              { text: csrp.fnes * 0.5, alignment: "center" },
              { text: csrp.fnts, alignment: "center" },
              {
                text: getCoursePosition(csrp.fnts, ccreport.fantse),
                alignment: "center",
              },
              { text: csrp.fngr, alignment: "center" },
              { text: csrp.fnrm, alignment: "center" },
            ],
            [
              { text: "French", bold: true },
              { text: csrp.fcs * 0.5, alignment: "center" },
              { text: csrp.fes * 0.5, alignment: "center" },
              { text: csrp.fts, alignment: "center" },
              {
                text: getCoursePosition(csrp.fts, ccreport.french),
                alignment: "center",
              },
              { text: csrp.fgr, alignment: "center" },
              { text: csrp.frm, alignment: "center" },
            ],
            csrp.class_category === "junior_high"
              ? // [
                // 	{ text: "Basic Design & T", bold: true },
                // 	{ text: csrp.bdcs * 0.5, alignment: 'center' },
                // 	{ text: csrp.bdes * 0.5, alignment: 'center' },
                // 	{ text: csrp.bdts, alignment: 'center' },
                // 	{ text: getCoursePosition(csrp.bdts, ccreport.bdt), alignment: 'center' },
                // 	{ text: csrp.bdgr, alignment: 'center' },
                // 	{ text: csrp.bdrm, alignment: 'center' }
                // ]
                [
                  {
                    text:
                      isNewCl(csrp.class_admitted) === false
                        ? "Basic Design & T"
                        : "Career Tech",
                    bold: true,
                  },
                  { text: csrp.ctcs * 0.5, alignment: "center" },
                  { text: csrp.ctes * 0.5, alignment: "center" },
                  { text: csrp.ctts, alignment: "center" },
                  {
                    text: getCoursePosition(csrp.ctts, ccreport.c_tech),
                    alignment: "center",
                  },
                  { text: csrp.ctgr, alignment: "center" },
                  { text: csrp.ctrm, alignment: "center" },
                ]
              : [
                  { text: "", bold: true },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                  {
                    text: "",
                    alignment: "center",
                  },
                  { text: "", alignment: "center" },
                  { text: "", alignment: "center" },
                ],
          ],
        },
      },
      {
        image: scale,
        width: 250,
        absolutePosition: { x: 335, y: 600 },
      },
      {
        text:
          "Attendance: " +
          csrp.attendance +
          "          Out of : " +
          csrp.total_days +
          "          Promoted to: " +
          (csrp.promoted_to || "_____"),
        absolutePosition: { x: 20, y: 620 },
      },
      {
        text:
          "Attitude: " +
          csrp.attitude +
          "                                                        Interest: " +
          csrp.interest,
        absolutePosition: { x: 20, y: 650 },
      },
      {
        text: "Conduct: " + csrp.conduct,
        absolutePosition: { x: 20, y: 680 },
      },
      {
        text: "Class Teacher's Remarks: " + csrp.remarks,
        absolutePosition: { x: 20, y: 710 },
      },
      {
        text: "Head Teacher's Remarks: " + csrp.ht_remarks,
        absolutePosition: { x: 20, y: 740 },
      },
      {
        text: "...........................................\nClass Teacher's Signature",
        absolutePosition: { x: 20, y: 770 },
      },
      {
        image: signature,
        width: 80,
        absolutePosition: { x: 400, y: 750 },
      },
      {
        text: "...........................................\nHead Teacher's Signature",
        absolutePosition: { x: 400, y: 770 },
      },
    ],
  };

  if (method === "print") {
    return pdfMake.createPdf(doc_deff).print();
  } else {
    return pdfMake
      .createPdf(doc_deff)
      .download(csrp.last_name + " " + csrp.other_names + "-" + csrp.term);
  }
}
