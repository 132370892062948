import React, { Component } from "react";
import _ from "lodash";
import {
	Grid,
	Table,
	Icon,
	Select,
	Checkbox,
	Form,
	Button,
	Header,
	Segment,
} from "semantic-ui-react";
import { all_classes, _days } from "../../assets/_calls/class_list";
import { weeks } from "../../assets/_calls/others";
import { db, auth } from "../../_services/firebase";
import Feedback from "../_on-pages/feedback";

class Attendance extends Component {
	constructor(props) {
		super();
		this.state = {
			day: "",
			week: "No Week Selected!",
			c_class: "No Class Selected!",
			term: "",
			current_class: [],
			_attendance: [],
			message: "",
			is_success: false,
			message_type: "",
		};
	}
	prepareFields = (event) => {
		event.preventDefault();
		if (this.props.students === null) {
			alert("No available Info");
		} else {
			const cc_data = _.filter(this.props.students, (cd) => {
				return cd.class_admitted === this.state.c_class;
			});
			const sorted_cc = _.sortBy(cc_data, (dt) => {
				return dt.last_name;
			});
			this.setState({ current_class: sorted_cc });
		}
	};

	markAll = () => {
        let temp = []
		for (var std of this.state.current_class) {
            temp.push({
                student_id: std.student_id,
				term: this.props.mode.current_term,
				presence: true,
            })
		}
        this.setState({_attendance:temp}, () => temp = [])

	};

	getAttendance = (status, index) => {
		const _status = _.find(this.state._attendance, (std) =>std.student_id === index && std.presence !== status);

		if (_status === undefined) {
			this.setState({
				_attendance: [
					...this.state._attendance,
					{
						student_id: index,
						term: this.props.mode.current_term,
						presence: status,
					},
				],
			});
		} else {
			// remove existing and add new attendance record.
			// _status.presence = status;
            const temp = this.state._attendance
            //eslint-disable-next-line
            const removed = _.remove(temp, (std) => std.student_id === index)
            this.setState({_attendance:temp})
		}
	};

	checkStatus = (id) => {
		const isHere = _.find(
			this.state._attendance,
			(std) => std.student_id === id && std.presence === true
		);
		return isHere === undefined ? false : true;
	};

	reportToday = (event) => {
		const todays_report = {
			date: new Date().toLocaleDateString(),
			processed_by: auth().currentUser.displayName,
			week_name: this.state.week,
			class: this.state.c_class,
			day: this.state.day,
			term: this.props.mode.current_term,
			year: new Date().getFullYear(),
			data: this.state._attendance,
		};
		let _marked = _.find(this.props.attendance, (pd) => {
			return (
				pd.day === this.state.day &&
				pd.term === this.props.mode.current_term &&
				pd.year === new Date().getFullYear() &&
				pd.week_name === this.state.week &&
				pd.class === this.state.c_class
			);
		});
		if (_marked === undefined) {
			db.collection("attendance")
				.add(todays_report)
				.then(() =>
					this.setState({
						is_success: true,
						message: "Reports Processing",
						message_type: "success",
						_attendance: [],
					})
				)
				.catch((err) => alert(err.message));
		} else {
			db.collection("attendance")
				.doc(_marked._id)
				.update(todays_report)
				.then(() =>
					this.setState({
						is_success: true,
						message: "Reports Update",
						message_type: "success",
						_attendance: [],
					})
				)
				.catch((err) => alert(err.message));

			// this.setState({is_success:true, message_type:'error', message:'Already Processed Today', _attendance:[]})
		}
	};
	checkDayStatus = (index, day, week) => {
		if (this.props.attendance === null) {
			return false;
		} else {
			const _currentS = _.find(this.props.attendance, (atd) => {
				return (
					atd.term === this.props.mode.current_term &&
					atd.day === day &&
					atd.week_name === week &&
					atd.year === new Date().getFullYear() &&
					atd.class === this.state.c_class
				);
			});

			if (_currentS === undefined) {
				return false;
			} else {
				let _status = _.find(_currentS.data, (cs) => {
					return cs.student_id === index;
				});

				if (_status === undefined) {
					return <Icon name="cancel" color="red" />;
				} else if (_status.presence === true) {
					return <Icon name="checkmark" color="green" />;
				} else {
					return <Icon name="cancel" color="red" />;
				}
			}
		}
	};

	isMarkedToday = (stage) => {
		let status = _.find(this.props.attendance, (cl) => {
			return (
				cl.class === stage &&
				new Date(cl.date).toLocaleDateString() ===
					new Date().toLocaleDateString() &&
				cl.term === this.props.mode.current_term
			);
		});

		if (status === undefined) {
			return [<Icon name="cancel" color="red" />, 0];
		} else {
			return [<Icon name="checkmark" color="green" />, status.data.length];
		}
	};

	getTotalStats = () => {
		return _.sumBy(all_classes, (cd) => {
			return parseInt(this.isMarkedToday(all_classes[cd.index].value)[1]);
		});
	};

	render() {
         const allChecked = this.state._attendance.length === this.state.current_class.length
		return (
			<Grid className="open-grids">
				<Feedback
					message={this.state.message}
					open_feedback={this.state.is_success}
					close_feedback={(name) => this.setState({ is_success: false })}
					message_type={this.state.message_type}
				/>

				<Grid.Column computer={4} mobile={16}>
					<Grid.Row>
						<Grid.Column computer={16}>
							<Segment raised>
								<Form
									onSubmit={this.prepareFields}
									inverted={this.props.mode.dark_mode}
								>
									<Form.Field
										label="Select Day"
										required
										control={Select}
										onChange={(e, target) =>
											this.setState({ day: target.value, _attendance: [] })
										}
										options={_days}
										placeholder="Select Day"
									/>

									<Form.Field
										label="Select Class"
										onChange={(e, target) =>
											this.setState({ c_class: target.value, _attendance: [] })
										}
										required
										control={Select}
										options={all_classes}
										placeholder="Select Class"
									/>
									<Form.Field
										label="Select Week"
										onChange={(e, target) =>
											this.setState({ week: target.value, _attendance: [] })
										}
										required
										control={Select}
										options={weeks}
										placeholder="Select Week"
									/>
									<Button
										type="submit"
										disabled={
											!this.state.day || !this.state.c_class || !this.state.week
										}
										icon="send"
										circular
										color="orange"
									/>
								</Form>
							</Segment>
							<Segment raised inverted={this.props.mode.dark_mode}>
								<Header
									content="Daily Student Attendance"
									subheader="Lower Primary - Junior High"
								/>
								<Table
									celled
									textAlign="center"
									inverted={this.props.mode.dark_mode}
								>
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell colSpan="3" textAlign="left">
												<Header
													inverted={this.props.mode.dark_mode}
													as="h3"
													textAlign="left"
													content={"Attendance Status"}
													subheader={
														"Today: " + new Date().toLocaleDateString()
													}
												/>
											</Table.HeaderCell>
										</Table.Row>
										<Table.Row>
											<Table.HeaderCell>Class</Table.HeaderCell>
											<Table.HeaderCell>Status</Table.HeaderCell>
											<Table.HeaderCell>Total</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{all_classes.map((grd, idx) => (
											<Table.Row key={grd.value}>
												<Table.Cell>{grd.value}</Table.Cell>
												<Table.Cell>
													{this.isMarkedToday(all_classes[idx]?.value)[0]}
												</Table.Cell>
												<Table.Cell>
													{this.isMarkedToday(all_classes[idx]?.value)[1]}
												</Table.Cell>
											</Table.Row>
										))}

										<Table.Row>
											<Table.Cell colSpan="2">Total Population</Table.Cell>
											<Table.Cell>{this.getTotalStats()}</Table.Cell>
										</Table.Row>
									</Table.Body>
								</Table>
							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid.Column>

				<Grid.Column computer={12} mobile={16}>
					{/* Time Table Here... Its kinda complicated */}
					<Segment raised>
						<Header
							content="Class Based Attendance"
							subheader={
								this.state.c_class + " - " + this.props.mode.current_term
							}
						/>
						<Table celled unstackable inverted={this.props.mode.dark_mode}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell rowSpan="2" colSpan="2">
										Student Names
									</Table.HeaderCell>
									<Table.HeaderCell colSpan="3" textAlign="center">
										{this.state.week}
									</Table.HeaderCell>
									<Table.HeaderCell colSpan="2" textAlign="center">
										{this.state.c_class}
									</Table.HeaderCell>
									<Table.HeaderCell textAlign="center">
										Total: {this.state.current_class.length}
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell>Monday</Table.HeaderCell>
									<Table.HeaderCell>Tuesday</Table.HeaderCell>
									<Table.HeaderCell>Wednesday</Table.HeaderCell>
									<Table.HeaderCell>Thursday</Table.HeaderCell>
									<Table.HeaderCell>Friday</Table.HeaderCell>
									<Table.HeaderCell>
										<Checkbox
											onClick={(e, data) => this.markAll()}
											label="Mark All"
											checked={allChecked}
										/>
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{this.state.current_class.length === 0 ? (
									<Table.Row>
										<Table.Cell colSpan={6} textAlign="center">
											<Header
												as="h3"
												content="Data not Loaded"
												subheader="Select Class to View Data"
											/>
										</Table.Cell>
									</Table.Row>
								) : (
									this.state.current_class.map((cc) => (
										<Table.Row key={cc.student_id}>
											<Table.Cell colSpan="2">
												{cc.last_name + "  " + cc.other_names}
											</Table.Cell>
											<Table.Cell>
												{this.checkDayStatus(
													cc.student_id,
													"Monday",
													this.state.week
												)}
											</Table.Cell>
											<Table.Cell>
												{this.checkDayStatus(
													cc.student_id,
													"Tuesday",
													this.state.week
												)}
											</Table.Cell>
											<Table.Cell>
												{this.checkDayStatus(
													cc.student_id,
													"Wednesday",
													this.state.week
												)}
											</Table.Cell>
											<Table.Cell>
												{this.checkDayStatus(
													cc.student_id,
													"Thursday",
													this.state.week
												)}
											</Table.Cell>
											<Table.Cell>
												{this.checkDayStatus(
													cc.student_id,
													"Friday",
													this.state.week
												)}
											</Table.Cell>
											<Table.Cell>
												<Checkbox
													toggle
													checked={this.checkStatus(cc.student_id)}
													onClick={(e, target) =>
														this.getAttendance(target.checked, cc.student_id)
													}
												/>
											</Table.Cell>
										</Table.Row>
									))
								)}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									{this.state.current_class === null ? (
										<Table.Cell>{""}</Table.Cell>
									) : (
										<Table.HeaderCell colSpan="8">
											<Button
												onClick={this.reportToday}
												positive
												floated="right"
												disabled={
													this.state._attendance.length === 0 ||
													this.props.read_only === true
												}
											>
												<Icon name="checkmark" /> Process Today's Attendance
											</Button>
										</Table.HeaderCell>
									)}
								</Table.Row>
							</Table.Footer>
						</Table>
					</Segment>
				</Grid.Column>
			</Grid>
		);
	}
}

export default Attendance;
