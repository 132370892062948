import React from 'react'
import { Table, Grid, Pagination, Icon } from 'semantic-ui-react'
import { FinanceGraph } from '../_graphs'
import _ from 'lodash'
import {all_classes} from '../../assets/_calls/class_list'

export default function FinanceStatus(props) {

    const [activePage, setActivePage] = React.useState(1)

    const getData = (_cls) => {
        // mistake checking from this side
        let tFees = 0.00
        
            if(props.mode !== undefined){
            
                if(_cls === all_classes[0].value || _cls === all_classes[1].value || _cls === all_classes[2].value || _cls === all_classes[3].value){
                        tFees = props.mode.pre_school_fees
                }
                else if(_cls === all_classes[4].value || _cls === all_classes[5].value || _cls === all_classes[6].value){
                        tFees = props.mode.lower_primary_fees
                }else if(_cls === all_classes[7].value || _cls === all_classes[8].value || _cls === all_classes[9].value ){
                    tFees = props.mode.upper_primary_fees
                }else if(_cls === all_classes[10].value || _cls === all_classes[11].value || _cls === all_classes[12].value || _cls === all_classes[13].value || _cls === all_classes[14].value || _cls === all_classes[15].value){
                    tFees = props.mode.junior_high_fees
                }
            }
        

        let _cc_stats = [0, 0, 0]

        if ((props.finance_data !== undefined || props.finance_data !== null) && (props.students_data !== null || props.student_data !== undefined) && (props.mode !== undefined || props.mode !== null)) {
            const cc_data = _.filter(props.finance_data, (std) => { return std.class_admitted === _cls && (std.term_paid === props.mode.current_term || std.term_paid === props.mode.current_semester) && new Date(std.date_paid).getFullYear() === new Date().getFullYear() })
            const amt_rec = _.sumBy(cc_data, (fnc) => { return parseFloat(fnc.amount_paid) })
            const t_i_c = _.filter(props.student_data, (std) => { return std.class_admitted === _cls }).length
            const total_expected = parseFloat(t_i_c * tFees).toFixed(2)
            const estimated_areas = parseFloat(total_expected - amt_rec).toFixed(2)


            _cc_stats = [parseFloat(amt_rec), parseFloat(total_expected), parseFloat(estimated_areas)]
        }
        return _cc_stats
    }

    const getStatus = (amount_paid, total_expected) => {
        return parseFloat((amount_paid / total_expected) * 100).toFixed(2)
        
    }

    const getOverallTotal = () => {

        const n1 = getData(all_classes[0].value)
        const n2 = getData(all_classes[1].value)
        const kg1 = getData(all_classes[2].value)
        const kg2 = getData(all_classes[3].value)
        const b1 = getData(all_classes[4].value)
        const b2 = getData(all_classes[5].value) 
        const b3 = getData(all_classes[6].value)
        const b4 = getData(all_classes[7].value)
        const b5 = getData(all_classes[8].value)
        const b6 = getData(all_classes[9].value)
        const b7 = getData(all_classes[10].value)
        const b8 = getData(all_classes[11].value)
        const b9 = getData(all_classes[12].value)
        const b10 = getData(all_classes[13].value)
        const j2 = getData(all_classes[14].value)
        const j3 = getData(all_classes[15].value)

        const amount_received = parseFloat(n1[0] + n2[0] + kg1[0] + kg2[0] + b1[0] + b2[0] + b3[0] + b4[0] + b5[0] + b6[0] + b7[0] + b8[0] + b9[0] + b10[0] + j2[0] + j3[0])
        const amount_expected = parseFloat(n1[1] + n2[1] + kg1[1] + kg2[1] + b1[1] + b2[1] + b3[1] + b4[1] + b5[1] + b6[1] + b7[1] + b8[1] + b9[1] + b10[1] + j2[1] + j3[1])
        const arrears = parseFloat(n1[2] + n2[2] + kg1[2] + kg2[2] + b1[2] + b2[2] + b3[2] + b4[2] + b5[2] + b6[2] + b7[2] + b8[2] + b9[2] + b10[2] + j2[2] + j3[2])
        
        return [amount_received, amount_expected, arrears]

    }

    const getPercentage = (value) => {
        if(value >= 50.00){
            return  <> <Icon name='level up alternate' color='green' />{value}%</>                         
        }else{
            return <> <Icon name='level down alternate' color='red' /> {value}%</>
        }
    }

    return (
        <Grid padded>
            <Grid.Column computer={6} mobile={16}>
                <FinanceGraph finance_data={props.finance_data} mode={props.mode}/>
            </Grid.Column>
            <Grid.Column computer={10}>
                <Table celled inverted={props.mode.dark_mode} unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan="2">Received: ₵ {getOverallTotal()[0]} </Table.HeaderCell>
                            <Table.HeaderCell>Expected Sum: ₵ {getOverallTotal()[1]} </Table.HeaderCell>
                            <Table.HeaderCell>Arrears: ₵ {getOverallTotal()[2]}</Table.HeaderCell>
                            <Table.HeaderCell>%: {getPercentage(getStatus(getOverallTotal()[0],getOverallTotal()[1]))}</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.HeaderCell>Class</Table.HeaderCell>
                            <Table.HeaderCell>Amount Received</Table.HeaderCell>
                            <Table.HeaderCell>Total Expected</Table.HeaderCell>
                            <Table.HeaderCell>Estimated Arears</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {activePage === 1 ?
                            <>
                            {all_classes.slice(0,5).map((cls, idx) => (
                                <Table.Row key={idx}>
                                <Table.Cell>{all_classes[idx].value}</Table.Cell>
                                <Table.Cell> ₵ {getData(all_classes[idx].value)[0]} </Table.Cell>
                                <Table.Cell>₵ {getData(all_classes[idx].value)[1]}</Table.Cell>
                                <Table.Cell>₵ {getData(all_classes[idx].value)[2]}</Table.Cell>
                                <Table.Cell>{getPercentage(getStatus(getData(all_classes[idx].value)[0], getData(all_classes[cls.index].value)[1]))}</Table.Cell>
                            </Table.Row>
                            ))}
                                
                                
                            </> : activePage === 2 ?
                                <>
                            {all_classes.slice(5,10).map((cls, idx) => (
                                <Table.Row key={idx}>
                                <Table.Cell>{all_classes[cls.index].value}</Table.Cell>
                                <Table.Cell> ₵ {getData(all_classes[cls.index].value)[0]} </Table.Cell>
                                <Table.Cell>₵ {getData(all_classes[cls.index].value)[1]}</Table.Cell>
                                <Table.Cell>₵ {getData(all_classes[cls.index].value)[2]}</Table.Cell>
                                <Table.Cell>{getPercentage(getStatus(getData(all_classes[cls.index].value)[0], getData(all_classes[cls.index].value)[1]))}</Table.Cell>

                            </Table.Row>
                            ))}
                                </> : activePage === 3 ? 
                                <>
                                {all_classes.slice(10,16).map((cls, idx) => (
                                    <Table.Row key={idx}>
                                    <Table.Cell>{all_classes[cls.index].value}</Table.Cell>
                                    <Table.Cell> ₵ {getData(all_classes[cls.index].value)[0]} </Table.Cell>
                                    <Table.Cell>₵ {getData(all_classes[cls.index].value)[1]}</Table.Cell>
                                    <Table.Cell>₵ {getData(all_classes[cls.index].value)[2]}</Table.Cell>
                                    <Table.Cell>{getPercentage(getStatus(getData(all_classes[cls.index].value)[0], getData(all_classes[cls.index].value)[1]))}</Table.Cell>

                                </Table.Row>
                                ))}
                             </>   
                            : null}

                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={7}>
                                <Pagination
                                    defaultActivePage={1}
                                    onPageChange={(e, data) => setActivePage(data.activePage)}
                                    firstItem={null}
                                    lastItem={null}

                                    secondary
                                    totalPages={3}
                                />
                            </Table.HeaderCell>
                        </Table.Row>

                    </Table.Footer>
                </Table>
            </Grid.Column>
        </Grid>
    )
}